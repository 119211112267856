import React from 'react';
import Segment from '@components/segment';
import Layout from '@components/layout';
import Seo from '@components/seo';
import HeroCTA from '@components/sections/heroCTA/heroCTA';
import GetStartedST from '@components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '@components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import OutcomeST from '@components/staticSections/OutcomeST/OutcomeST';
import Treatments from '@components/staticSections/Treatments/Treatments';
import SocialProofQuoteCardST from '@components/staticSections/SocialProofQuoteCardST/SocialProofQuoteCardST';
import Faq from '@components/sections/faq/faq';

const IndexPage = () => {
  const lang = 'fr_fr';
  return (
    <Layout
      lang="fr"
      headerWitdh="header"
      headerStyle="flex--space-between"
      showFooter
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_FR} />
      <Seo title="Partenaire | Joint Academy" language="fr" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedataNoCerts
        dataSection="hero_CTA"
        alt=""
        title="Une appli pour soulager l’arthrose et les douleurs articulaires"
        titleClassName="bold"
        text="Soulagez vos douleurs articulaires par l’exercice, jour après jour, accompagné par votre kiné."
        tagHeadline="Aucun frais. Entrez votre numéro de téléphone et téléchargez l'application."
        smsTag
      />
      <Treatments lang={lang} downloadhref="#herotext" padBottom={false} />
      <SocialProofQuoteCardST
        lang={lang}
        dataTheme="white"
        extraMargin="margin-top--lg"
      />
      <GetStartedST
        lang={lang}
        renderDownloadButton
        ctaButtonText="C’EST PARTI !"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="#herotext"
        extraContent={<div className="text--center margin-top--sm">Des questions ? Consultez notre <a href="#faq">FAQ.</a></div>}
      />
      <BgCardsTextST dataTheme="light" lang={lang} />
      <SocialProofFeedST lang={lang} sectionClassName="margin-bottom--lg" />
      <OutcomeST lang={lang} />

      <Faq
        dataTheme="white"
        borderTop=""
        title="Comment pouvons-nous vous aider ?"
        faqs={[
          {
            question: 'Qui peut rejoindre Joint Academy ?',
            answer:
              'Pour rejoindre Joint Academy, vous devez être patient d’un kinésithérapeute libéral. Votre kinésithérapeute peut vous donner accès à l’application via une invitation.\n'
              + '\n'
              + 'Nos programmes sont conçus pour soulager les douleurs de la hanche et du genou, la lombalgie et les douleurs aux épaules. Lors de votre inscription, vous serez amené à remplir un questionnaire de santé qui permettra de vérifier que le programme vous convient.',
          },
          {
            question: 'En quoi consiste le programme de Joint Academy ?',
            answer:
              'Joint Academy vous aide à soulager vos douleurs articulaires grâce à des exercices adaptés et ciblés. Vous recevez, via l’application Joint Academy, deux exercices à effectuer chaque jour, mais aussi des leçons pour mieux comprendre vos symptômes et des conseils au quotidien.',
          },
          {
            question: 'Combien coûte le programme ?',
            answer:
              'L’application Joint Academy est entièrement gratuite.',
          },
          {
            question: 'Comment mes données de santé sont-elles stockées ?',
            answer:
              'Toutes vos données personnelles et de santé sont stockées chez un hébergeur de santé sécurisé et tous les professionnels utilisateurs de la plateforme Joint Academy pratiquent le secret médical.',
          },
          {
            question:
              'Les exercices me semblent trop difficiles',
            answer:
              'Pas d’inquiétude ! Les exercices proposés tout au long du programme sont conçus pour les personnes souffrant de douleurs articulaires. Vous commencerez votre programme avec des exercices faciles et rapides : en moyenne 5 minutes par jour. Vous pouvez laisser un commentaire après chaque exercice pour que le programme soit adapté à vos besoins et vos douleurs.',
          },
          {
            question: 'Je n’ai pas de kinésithérapeute',
            answer:
              'Si vous n’avez pas de kinésithérapeute, contactez-nous à support@jointacademy.fr. Nous vous aiderons à trouver un kinésithérapeute qui propose Joint Academy près de chez vous.',
          },
        ]}
      />
    </Layout>
  );
};

export default IndexPage;
